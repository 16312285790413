@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url(../../../static/fonts/Montserrat/Montserrat-Light.ttf);
}

@font-face {
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 400;
    src: url(../../../static/fonts/Montserrat/Montserrat-Regular.ttf);
}

@font-face {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    src: url(../../../static/fonts/Montserrat/Montserrat-Medium.ttf);
}

@font-face {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 700;
    src: url(../../../static/fonts/Montserrat/Montserrat-Bold.ttf);
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url(../../../static/fonts/Montserrat/Montserrat-ExtraBold.ttf);
}

.content {
    font-family: Montserrat, Verdana, sans-serif;
    position: relative;

    .inner {
        height: 100%;
        padding: 0 2rem;

        .wrap {
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;

            .authForm {
                width: 780px;
                height: 530px;
                display: flex;
                flex-direction: row;
                box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.15);
                overflow: hidden;
                border-radius: 10px;

                .logo {
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    z-index: 101;
                }

                .leftSide,
                .rightSide {
                    position: relative;
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 50px;
                    overflow: hidden;
                    text-align: center;

                    .form {
                        .row {
                            position: relative;
                            margin-bottom: 12px;

                            h2 {
                                font-family: Montserrat-Bold, Verdana, sans-serif;
                                font-size: 28px;
                                margin-bottom: 18px;
                                color: #28093B;
                            }

                            .authSocialTitle {
                                font-size: 14px;
                            }

                            .authSocial {
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                margin-top: 10px;
                                list-style: none;

                                li {
                                    margin-right: 20px;

                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }

                            .shortText {
                                font-size: 14px;
                                margin-bottom: 28px;
                            }

                            .forgotLink {
                                background-image: linear-gradient(to right, #2B00CE, #BA46A8, #FF9F22);
                                background-clip: text;
                                color: transparent;
                                text-decoration: underline;

                                &:hover {
                                    cursor: pointer;
                                    opacity: .8;
                                }
                            }

                            input {
                                font-family: Montserrat-Regular, Verdana, sans-serif;
                                width: 280px;
                                background: #F1F4F7;
                                border: 1px solid #FFFFFF;
                                box-sizing: border-box;
                                border-radius: 12px;
                                padding: 14px 18px;
                                font-size: 16px;
                                outline: none;

                                &.labelError {
                                    background-color: #FFE7E7;
                                }
                            }

                            .textError {
                                color: #EC6D70;
                                font-size: 13px;
                                margin-bottom: 18px;
                            }

                            .textSuccess {
                                color: #1aac45;
                                font-size: 13px;
                                margin-bottom: 18px;
                            }

                            .button {
                                display: inline-block;
                                color: #fff;
                                background: linear-gradient(to right, #2B00CE, #BA46A8, #FF9F22);
                                text-decoration: none;
                                border: none;
                                box-sizing: border-box;
                                border-radius: 50px;
                                height: 48px;
                                width: 240px;
                                color: #fff;
                                font-size: 16px;

                                &:hover {
                                    cursor: pointer;
                                    opacity: .8;
                                }
                            }

                            .eye {
                                width: 16px;
                                height: 16px;
                                background: red;
                                display: inline-block;
                                position: absolute;
                                right: 20px;
                                top: 15px;
                                background: url('../../../static/images/noteye.png');
                                background-size: cover;

                                &:hover {
                                    cursor: pointer;
                                }
                            }

                            .showPassword {
                                background: url('../../../static/images/eye.png');
                                background-size: cover;
                            }
                        }

                        .mobileVersion {
                            display: none;
                            margin-top: 24px;
                        }
                    }

                    .mask {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: url('../../../static/images/backimg.png');
                        background-size: cover;
                        width: 100%;
                        height: 100%;
                        transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
                        z-index: 100;

                        .maskInner {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;

                            h2 {
                                font-size: 28px;
                                color: #fff;
                                text-align: center;
                                margin-bottom: 20px;
                                font-family: Montserrat-Bold, Verdana, sans-serif;
                            }

                            p {
                                font-family: Montserrat-Regular, Verdana, sans-serif;
                                font-size: 12px;
                                color: #fff;
                                text-align: center;
                                margin-bottom: 30px;
                            }

                            .button {
                                border: 1px solid #FFFFFF;
                                box-sizing: border-box;
                                border-radius: 30px;
                                text-align: center;
                                min-width: 150px;
                                color: #fff;
                                padding: 12px 34px;

                                &:hover {
                                    cursor: pointer;
                                    opacity: .8;
                                }
                            }

                            img {
                                margin-top: 30px;
                            }
                        }
                    }

                    .firstState {
                        transform: translateX(100%);
                    }

                    .firstInnerState {
                        transform: translateX(200%);
                    }

                    .secondState {
                        transform: translateX(-100%);
                    }

                    .secondInnerState {
                        transform: translateX(-200%);
                    }
                }
            }
        }

        @media (max-width: 840px) {
            .wrap {
                .authForm {
                    width: 340px;

                    .logo img {
                        width: 103px;
                        height: 31px;
                    }

                    .leftSide,
                    .rightSide {
                        .form {
                            .row {
                                input {
                                    width: 100%;
                                }
                            }

                            .mobileVersion {
                                display: block;
                            }
                        }
                    }

                    .mobileHide {
                        display: none;
                    }

                    .mobileWidth {
                        width: 100%;
                    }
                }
            }
        }
    }
}