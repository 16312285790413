@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url(../../../static/fonts/Montserrat/Montserrat-Light.ttf);
}
@font-face {
  font-family: 'Montserrat-Regular';
  font-style: normal;
  font-weight: 400;
  src: url(../../../static/fonts/Montserrat/Montserrat-Regular.ttf);
}
@font-face {
  font-family: 'Montserrat-Medium';
  font-style: normal;
  font-weight: 500;
  src: url(../../../static/fonts/Montserrat/Montserrat-Medium.ttf);
}
@font-face {
  font-family: 'Montserrat-Bold';
  font-style: normal;
  font-weight: 700;
  src: url(../../../static/fonts/Montserrat/Montserrat-Bold.ttf);
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url(../../../static/fonts/Montserrat/Montserrat-ExtraBold.ttf);
}
.content {
  font-family: Montserrat, Verdana, sans-serif;
  position: relative;
}
.content .inner {
  height: 100%;
  padding: 0 2rem;
}
.content .inner .wrap {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content .inner .wrap .authForm {
  width: 780px;
  height: 530px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: 10px;
}
.content .inner .wrap .authForm .logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 101;
}
.content .inner .wrap .authForm .leftSide,
.content .inner .wrap .authForm .rightSide {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  overflow: hidden;
  text-align: center;
}
.content .inner .wrap .authForm .leftSide .form .row,
.content .inner .wrap .authForm .rightSide .form .row {
  position: relative;
  margin-bottom: 12px;
}
.content .inner .wrap .authForm .leftSide .form .row h2,
.content .inner .wrap .authForm .rightSide .form .row h2 {
  font-family: Montserrat-Bold, Verdana, sans-serif;
  font-size: 28px;
  margin-bottom: 18px;
  color: #28093B;
}
.content .inner .wrap .authForm .leftSide .form .row .authSocialTitle,
.content .inner .wrap .authForm .rightSide .form .row .authSocialTitle {
  font-size: 14px;
}
.content .inner .wrap .authForm .leftSide .form .row .authSocial,
.content .inner .wrap .authForm .rightSide .form .row .authSocial {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  list-style: none;
}
.content .inner .wrap .authForm .leftSide .form .row .authSocial li,
.content .inner .wrap .authForm .rightSide .form .row .authSocial li {
  margin-right: 20px;
}
.content .inner .wrap .authForm .leftSide .form .row .authSocial li:last-child,
.content .inner .wrap .authForm .rightSide .form .row .authSocial li:last-child {
  margin-right: 0;
}
.content .inner .wrap .authForm .leftSide .form .row .shortText,
.content .inner .wrap .authForm .rightSide .form .row .shortText {
  font-size: 14px;
  margin-bottom: 28px;
}
.content .inner .wrap .authForm .leftSide .form .row .forgotLink,
.content .inner .wrap .authForm .rightSide .form .row .forgotLink {
  background-image: linear-gradient(to right, #2B00CE, #BA46A8, #FF9F22);
  background-clip: text;
  color: transparent;
  text-decoration: underline;
}
.content .inner .wrap .authForm .leftSide .form .row .forgotLink:hover,
.content .inner .wrap .authForm .rightSide .form .row .forgotLink:hover {
  cursor: pointer;
  opacity: 0.8;
}
.content .inner .wrap .authForm .leftSide .form .row input,
.content .inner .wrap .authForm .rightSide .form .row input {
  font-family: Montserrat-Regular, Verdana, sans-serif;
  width: 280px;
  background: #F1F4F7;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 14px 18px;
  font-size: 16px;
  outline: none;
}
.content .inner .wrap .authForm .leftSide .form .row input.labelError,
.content .inner .wrap .authForm .rightSide .form .row input.labelError {
  background-color: #FFE7E7;
}
.content .inner .wrap .authForm .leftSide .form .row .textError,
.content .inner .wrap .authForm .rightSide .form .row .textError {
  color: #EC6D70;
  font-size: 13px;
  margin-bottom: 18px;
}
.content .inner .wrap .authForm .leftSide .form .row .textSuccess,
.content .inner .wrap .authForm .rightSide .form .row .textSuccess {
  color: #1aac45;
  font-size: 13px;
  margin-bottom: 18px;
}
.content .inner .wrap .authForm .leftSide .form .row .button,
.content .inner .wrap .authForm .rightSide .form .row .button {
  display: inline-block;
  background: linear-gradient(to right, #2B00CE, #BA46A8, #FF9F22);
  text-decoration: none;
  border: none;
  box-sizing: border-box;
  border-radius: 50px;
  height: 48px;
  width: 240px;
  color: #fff;
  font-size: 16px;
}
.content .inner .wrap .authForm .leftSide .form .row .button:hover,
.content .inner .wrap .authForm .rightSide .form .row .button:hover {
  cursor: pointer;
  opacity: 0.8;
}
.content .inner .wrap .authForm .leftSide .form .row .eye,
.content .inner .wrap .authForm .rightSide .form .row .eye {
  width: 16px;
  height: 16px;
  background: red;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 15px;
  background: url('../../../static/images/noteye.png');
  background-size: cover;
}
.content .inner .wrap .authForm .leftSide .form .row .eye:hover,
.content .inner .wrap .authForm .rightSide .form .row .eye:hover {
  cursor: pointer;
}
.content .inner .wrap .authForm .leftSide .form .row .showPassword,
.content .inner .wrap .authForm .rightSide .form .row .showPassword {
  background: url('../../../static/images/eye.png');
  background-size: cover;
}
.content .inner .wrap .authForm .leftSide .form .mobileVersion,
.content .inner .wrap .authForm .rightSide .form .mobileVersion {
  display: none;
  margin-top: 24px;
}
.content .inner .wrap .authForm .leftSide .mask,
.content .inner .wrap .authForm .rightSide .mask {
  position: absolute;
  top: 0;
  left: 0;
  background: url('../../../static/images/backimg.png');
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  z-index: 100;
}
.content .inner .wrap .authForm .leftSide .mask .maskInner,
.content .inner .wrap .authForm .rightSide .mask .maskInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
.content .inner .wrap .authForm .leftSide .mask .maskInner h2,
.content .inner .wrap .authForm .rightSide .mask .maskInner h2 {
  font-size: 28px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  font-family: Montserrat-Bold, Verdana, sans-serif;
}
.content .inner .wrap .authForm .leftSide .mask .maskInner p,
.content .inner .wrap .authForm .rightSide .mask .maskInner p {
  font-family: Montserrat-Regular, Verdana, sans-serif;
  font-size: 12px;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}
.content .inner .wrap .authForm .leftSide .mask .maskInner .button,
.content .inner .wrap .authForm .rightSide .mask .maskInner .button {
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 30px;
  text-align: center;
  min-width: 150px;
  color: #fff;
  padding: 12px 34px;
}
.content .inner .wrap .authForm .leftSide .mask .maskInner .button:hover,
.content .inner .wrap .authForm .rightSide .mask .maskInner .button:hover {
  cursor: pointer;
  opacity: 0.8;
}
.content .inner .wrap .authForm .leftSide .mask .maskInner img,
.content .inner .wrap .authForm .rightSide .mask .maskInner img {
  margin-top: 30px;
}
.content .inner .wrap .authForm .leftSide .firstState,
.content .inner .wrap .authForm .rightSide .firstState {
  transform: translateX(100%);
}
.content .inner .wrap .authForm .leftSide .firstInnerState,
.content .inner .wrap .authForm .rightSide .firstInnerState {
  transform: translateX(200%);
}
.content .inner .wrap .authForm .leftSide .secondState,
.content .inner .wrap .authForm .rightSide .secondState {
  transform: translateX(-100%);
}
.content .inner .wrap .authForm .leftSide .secondInnerState,
.content .inner .wrap .authForm .rightSide .secondInnerState {
  transform: translateX(-200%);
}
@media (max-width: 840px) {
  .content .inner .wrap .authForm {
    width: 340px;
  }
  .content .inner .wrap .authForm .logo img {
    width: 103px;
    height: 31px;
  }
  .content .inner .wrap .authForm .leftSide .form .row input,
  .content .inner .wrap .authForm .rightSide .form .row input {
    width: 100%;
  }
  .content .inner .wrap .authForm .leftSide .form .mobileVersion,
  .content .inner .wrap .authForm .rightSide .form .mobileVersion {
    display: block;
  }
  .content .inner .wrap .authForm .mobileHide {
    display: none;
  }
  .content .inner .wrap .authForm .mobileWidth {
    width: 100%;
  }
}
